* {
  font-family: "Lato", sans-serif;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  transition: all 200ms ease;
}
:root {
  --primary: #fff;
  --native: #a46f43;
  --native-hover: #a57048;
}
body {
  margin: 0px;
  padding: 0px;
}
h1 {
  font-weight: 900;
}
div.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
h1.white,
h5 {
  color: #fff;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.margin90 {
  width: 90%;
  margin: auto;
}
.margin80 {
  width: 80%;
  margin: auto;
  max-width: 960px;
}
.margin60 {
  width: 60%;
  margin: auto;
  max-width: 960px;
}
.header {
  position: absolute;
  font-size: 22px;
  text-align: center;
  width: 100%;
}

.navbar {
  width: 80%;
  margin: auto;
  position: sticky;
  top: 0px;
  max-width: 750px;
  margin-top: -1px;
}

.logo {
  width: 30%;
  margin: auto;
  border-radius: 0px 0px 8px 8px;
}

.nav {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav a {
  text-decoration: none;
  display: inline-block;
}
.nav a li {
  display: inline;
  margin: 10px;
  padding: 15px;
  font-size: 15px;
  padding-bottom: 5px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fcfcfc;
}
.nav a li {
  border-bottom: 3px solid transparent;
}
.nav a:hover li {
  border-bottom-color: #d49f73 !important;
  color: #fff;
}
.nav a li.current {
  border-bottom-color: #d49f73 !important;
  color: #fff;
}
.clear {
  clear: both;
  padding: 10px;
}
.no-padding {
  padding: 0 !important;
}
.center {
  text-align: center;
}
.serviceFloat {
  display: inline-block;
  background: #fff;
  border-radius: 5px;
  padding-top: #fff;
  width: 98%;
  margin: auto;
}
.serviceFloatButtons {
  display: block;
  width: 100%;
  padding: 20px 0px;
  border: 0px;
  background: var(--native);
  border-radius: 0px 0px 5px 5px;
  color: #fff;
  margin-top: 15px;
  box-shadow: 0px -8px 12px #ccc;
}
.important {
  background: whitesmoke;
  padding: 20px;
  border-radius: 5px;
}
.serviceFloatButtons:hover {
  background: var(--native-hover);
  color: #fff;
}
.banner {
  background:
    linear-gradient(140deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(../images/banner2.jpg) center fixed;
  background-size: cover;
  display: flex;
  flex-direction: row; /* make main axis horizontal (default setting) */
  justify-content: center; /* center items horizontally, in this case */
  align-items: center;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100vh;
  pointer-events: all;
  width: 100%;
  min-height: 507px;
}
.banner.smaller {
  height: 35vh;
}
.bannerText {
  height: 70px;
  text-align: center;
  color: #fff;
  font-size: 35px;
  font-weight: 900;
  margin-top: 15%;
}
.bannerText .letsTalkLight {
  margin-top: 85px;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  transform: translatey(0px);
  animation: float 5s ease-in-out infinite;
  border: 2px solid #fcfcfc;
  background: #fcfcfc;
  width: auto !important;
}
.letsTalkLight {
  display: inline-block;
  margin-top: 10px;
  padding: 15px 25px;
  border: 2px solid var(--native);
  background: transparent;
  color: var(--native);
  cursor: pointer;
  font-size: 16px !important;
  border-radius: 8px;
  width: 100%;
}
.letsTalkLight:hover {
  background: #fff;
}
.letsTalkDark {
  display: block;
  margin-top: 10px;
  padding: 15px 0px;
  border: 2px solid #333;
  background: transparent;
  color: #333;
  cursor: pointer;
  font-size: 16px !important;
  width: 100%;
  border-radius: 5px;
  text-align: center;
}
.letsTalkDark:hover {
  border: 2px solid #333;
  background: #333;
  color: #fff;
}
.post li {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #333;
}
.aboutme {
  padding: 10px 0px;
}
.halfhalf {
  width: 50%;
  float: left;
}
.contact .halfhalf {
  width: 50%;
  float: left;
  height: 100% !important;
}
.headshot {
  max-width: 65%;
  display: block;
  margin: 0 auto;
  margin-top: 25px;
  border-radius: 8px;
}
.headshotAboutMe {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  margin-top: 25px;
  border-radius: 8px;
}
.aboutme h1 {
  text-align: center;
}
.services {
  /* display: flex; */
  padding: 15px 0;
  background: whitesmoke;
}
.services h1,
.services h3 {
  text-align: center;
}
.threes {
  width: 33.3%;
  display: inline-block;
}
.justify {
  text-align: justify;
}
.contact {
  min-height: 500px;
  background: whitesmoke;
  overflow: auto;
}
.contactForm {
  padding-bottom: 15px;
}
form p {
  margin-bottom: 5px;
}
span.error {
  margin-bottom: 10px !important;
  color: red;
}
.errorField {
  border-bottom: 2px solid red !important;
}
.mapWrapper {
  height: 250px;
  width: 100%;
}
.relative {
  height: 100%;
  overflow: hidden;
  padding-right: 0px;
  box-shadow: -4px 0px 14px 1px #ccc;
}
.mapIframe {
  border: 0;
  height: 100vh;
  left: 0;
  /* position: absolute; */
  top: 0;
  width: 100%;
  max-height: 550px;
  margin-bottom: -5px;
}
.col-xs-12 {
  padding: 0;
}
input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  padding: 15px 25px;
  border: 0px;
  border-bottom: 2px solid #333;
  box-sizing: border-box;
  width: 100%;
}
textarea {
  resize: none;
}
.halfhalf.dark {
  background: whitesmoke;
}
h4 {
  text-align: center;
  color: #fff;
  margin: 10px 0;
}
.social {
  overflow: auto;
  background: linear-gradient(
      140deg,
      rgba(0, 0, 0, 0.8),
      rgba(120, 100, 100, 0.8)
    )
    center fixed;
  min-height: 150px;
  text-align: center;
  padding-bottom: 20px;
}
.social-icons ul {
  list-style: none;
  padding-left: 0 !important;
  margin: 0;
}
.social-icons ul li {
  color: #fff;
  display: inline-block;
  margin: 0 15px;
  font-size: 45px;
}
.icon {
  color: #fff;
  display: inline-block;
  margin: 0 15px;
  font-size: 45px;
}
.post {
  padding: 15px 0px;
  font-size: 20px;
}
.footer {
  text-align: center;
  min-height: 25px;
  overflow: auto;
  padding: 5px 0px;
  background: linear-gradient(140deg, rgba(0, 0, 25, 0.8), rgba(0, 0, 15, 0.8));
  background-size: cover;
}
.footer h4 {
  line-height: 80px;
  padding: 0;
  margin: 0;
}
.noLeftMargin {
  margin-left: 0 !important;
  width: 95%;
}
.verticalMargins {
  margin: 5px 0px;
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}
